@tailwind base;

@layer base {
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-xl;
    }
    h3 {
      @apply text-lg;
    }
  }

@tailwind components;

.content-h1{
  @apply text-lg sm:text-xl md:text-2xl lg:text-4xl font-bold py-2;
}

.content-p{
  @apply text-base sm:text-lg md:text-xl lg:text-2xl pt-2 pb-8;
}

.content-title-wrapper{
  @apply p-4 flex items-center justify-center flex-col;
}

.content-subtitle
{
  @apply text-xs sm:text-sm md:text-base lg:text-lg;
}
.content-date
{
  @apply text-xs sm:text-sm md:text-base lg:text-lg font-medium;
}

.content-title-h1{
  @apply text-xl sm:text-2xl md:text-4xl lg:text-6xl font-bold text-center;
}

.content-link{
  @apply text-indigo-700 underline font-extrabold hover:cursor-pointer;
}

@tailwind utilities;